
import { timer as observableTimer, Observable, Subscription } from 'rxjs';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import * as auth0 from 'auth0-js';
import { AuthConfigService } from './auth-config.service';
import { JwtHelper } from 'angular2-jwt';
// import { AuthApiService } from '../services/shared/auth-api.service';
// import { SibMasterService } from '../services/sib-master.service';

@Injectable()
export class AuthService {

  // auth0 = new auth0.WebAuth();

  config: any;
  apiConfigCredentials: any;
  private permissions = '';
  jwtHelper: JwtHelper = new JwtHelper();

  @Output() emitProifle: EventEmitter<any> = new EventEmitter<any>();

  userProfile: any;
  auth0: any;
  refreshSubscription: Subscription;
  constructor(public router: Router,
    private authConfigService: AuthConfigService
  ) {

    this.apiConfigCredentials = authConfigService.config.apiConfigCredentials;
    // this.auth0 = new auth0.WebAuth(authConfigService.config.authConfig)
  }

  public login(): void {
    if (!this.auth0) {
      // this.handleAuthentication();
      // this.setConfig(JSON.parse(localStorage.getItem('auth-web-config')));
      // tslint:disable-next-line: max-line-length
      // this.setConfig({ 'clientID': 'dgq2dHqb3SI7opU4eLDORKlmucGDmEvb', 'domain': 'admin-vendor.auth0.com', 'responseType': 'token id_token', 'audience': 'sib-admin-api', 'redirectUri': 'http://vendor-admin.showitbig.com:4300/', 'scope': 'openid' });
      this.setConfig(this.authConfigService.getConfig()['authConfig']);
    }
    this.auth0.authorize();
  }


  setConfig(config) {
    if (config) {
      this.config = config;
      this.auth0 = new auth0.WebAuth(this.config);
    }
  }


  public handleAuthentication(): void {
    // this.setConfig(JSON.parse(localStorage.getItem('auth-web-config')));
    // tslint:disable-next-line: max-line-length
    // this.setConfig({ 'clientID': 'dgq2dHqb3SI7opU4eLDORKlmucGDmEvb', 'domain': 'admin-vendor.auth0.com', 'responseType': 'token id_token', 'audience': 'sib-admin-api', 'redirectUri': 'http://vendor-admin.showitbig.com:4300/', 'scope': 'openid' });
    this.setConfig(this.authConfigService.getConfig()['authConfig']);
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        window.location.hash = '';
        this.setSession(authResult);
        this.setPermissions(authResult);
        this.getProfile((error, profile) => {
          localStorage.setItem('profile', JSON.stringify(profile));
          this.userProfile = profile;
        });
        this.router.navigate(['']);
      } else if (err) {
        this.router.navigate(['']);
        // console.log(err);
      }
    });
  }

  public getProfile(cb): void {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('Access token must exist to fetch profile');
    }

    const self = this;
    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        self.userProfile = profile;
        localStorage.setItem('profile', JSON.stringify(profile));
        this.emitProifle.emit(profile);
      }
      cb(err, profile);
    });
  }

  public getToken(): string {
    return localStorage.getItem('access_token');
  }

  public getMasterToken(): string {
    return localStorage.getItem('master_access_token');
  }

  public getAuthApiToken(): string {
    return localStorage.getItem('api_access_token');
  }

  public getUserProfile(): any {
    this.userProfile = JSON.parse(localStorage.getItem('profile'));
    return this.userProfile;
  }

  public getUserInfo(): any {
    const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0] : null;
    return userInfo;
  }

  public getUsermanagementToken() {
    return localStorage.getItem('usermanagement_access_token');
  }

  setSession(authResult): void {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);

    this.scheduleRenewal();
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('profile');
    // this.login();
    if (!this.auth0) {
      // this.handleAuthentication();
      this.setConfig(JSON.parse(localStorage.getItem('auth-web-config')));
    }
    this.auth0.logout();

    // Go back to the home route
    // this.router.navigate(['/']);
    // this.unscheduleRenewal();
    // setTimeout(() => {
    //     this.login();
    // }, 500)
  }

  private setPermissions(authResult) {

    this.permissions = this.jwtHelper.decodeToken(authResult.accessToken).scope;
  }

  public getPermissions() {
    this.permissions = this.jwtHelper.decodeToken(localStorage.getItem('access_token')).scope;
    return this.permissions;
  }
  public isAuthenticated(): boolean {

    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    // return new Date().getTime() < expiresAt;
    return new Date().getTime() < expiresAt;
    // return false;
  }

  public renewToken() {
    this.logout();
    this.login();

    // let parent = this;

    // this.auth0.checkSession({},
    //     function(err, result) {
    //       if (err) {
    //         console.log(err);
    //       } else {
    //         parent.setSession(result);
    //       }
    //     }
    //   );

  }

  public scheduleRenewal() {
    if (!this.isAuthenticated()) { return; }
    this.unscheduleRenewal();
    const expiresAt = JSON.parse(window.localStorage.getItem('expires_at'));
    const preExpiryAt = expiresAt;
    const delay = preExpiryAt - Date.now();

    if (delay > 0) {
      const source = setTimeout(() => {
        this.renewToken();
      }, delay);
    }

    const source = observableTimer(Math.max(1, preExpiryAt - Date.now()));
    /* const source = Observable.of(preExpiryAt).flatMap(
        preExpiryAt => {

            const now = Date.now();

            // Use the delay in a timer to
            // run the refresh at the proper time
            return Observable.timer(Math.max(1, preExpiryAt - now));
        }); */

    // expiresAt = JSON.parse("1534766580000");
    /* const source = Observable.of(expiresAt).flatMap(
    expiresAt => {
    const now = Date.now();
    // Use the delay in a timer to
    // run the refresh at the proper time
    return Observable.timer(Math.max(1, expiresAt - now));
    });
    // Once the delay time from above is
    // reached, get a new JWT and schedule
    // additional refreshes
    this.refreshSubscription = source.subscribe(() => {
    this.renewToken();
    this.scheduleRenewal();
    }); */
    // this.refreshSubscription =
    this.refreshSubscription = source.subscribe(() => {
      this.renewToken();
      this.scheduleRenewal();
    });

  }

  public unscheduleRenewal() {
    if (!this.refreshSubscription) { return; }
    this.refreshSubscription.unsubscribe();
  }

  /**
   *to check the permission
   *
   * @param {string} permission
   * @returns
   * @memberof AuthService
   */
  public checkPermission(permission: string) {
    if (this.getPermissions().indexOf(permission) !== -1) {
      return true;
    } else {
      return false;
    }
  }

}
