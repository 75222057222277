import { Injectable } from '@angular/core';

@Injectable()
export class AuthConfigService {

  config: any = {};


  private admin_vendor = {
    // sib-admin-api
    // authApiUrl: 'https://admin-vendor1.auth0.com/api/v2/',
    // apiAccessTokenUrl: 'https://admin-vendor1.auth0.com/oauth/token',
    // apiConfigCredentials: {
    //   'grant_type': 'client_credentials',
    //   'client_id': '536AUGCwk0YU7ybOiRqTdJotPWoR6R3q',
    //   'client_secret': 'IkPjpDBG81NBe0Z-T0VhanJkVZsbXUZ1uF11StZm9JpF9UwnMaygpYmbjEJPIS_y',
    //   'audience': 'https://admin-vendor1.auth0.com/api/v2/'
    // },

    // auth0-authorization-extension-api(Test Application)
    // authUserManagementConfig: {
    //   'client_id': 'zL25o4aT988An3HL5634Ho6gsP3MoaZ5',
    //   'client_secret': '60f0oNkMnKtu95yxn3Q26EkYu4i9b3v6lpnINylsLw9sjFxBMHU03F3FZbOpSA8L',
    //   'audience': 'urn:auth0-authz-api',
    //   'grant_type': 'client_credentials'
    // },

    // admin-showitbig
    // authConfig: {
    // clientID: '493pg3Wg5u322M213qsnrcrt2MJPxghr',
    // // clientId : 'rEBP8b12yPBEZmkDMnA9l8D9nffKkApv',
    // domain: 'admin-vendor1.auth0.com',
    // responseType: 'token id_token',
    // audience: 'sib-admin-api',
    // redirectUri: 'https://admin-vendor1.showitbig.com/',
    // scope: 'openid'
    // }



    authApiUrl: 'https://admin-vendor.auth0.com/api/v2/',
    apiAccessTokenUrl: 'https://admin-vendor.auth0.com/oauth/token',
    // apiConfigCredentials: {
    //   'grant_type': 'client_credentials',
    //   'client_id': 'ZOWugsDhMw7qKPESMuJnVm1OatvTpSWZ',
    //   'client_secret': '0oV9SaxNtkAwIGMYNBNN2M3ciiVEhkyX5tuN8931yUVEmQLKO2ntTEhMygFT59sG',
    //   'audience': 'https://admin-vendor.auth0.com/api/v2/'
    // },

    // // auth0-authorization-extension-api(Test Application)
    // authUserManagementConfig: {
    //   'client_id': 'Vphjt7Vqf8wuXtbt4RXN3uzaHvS36AIG',
    //   'client_secret': 'hqhWuKnu3otRicLDCApEYVIcBtUig3dCNopCHZGBWmWjgqbk-rjmsRtGk3W1D2I1',
    //   'audience': 'urn:auth0-authz-api',
    //   'grant_type': 'client_credentials'
    // },

    // admin-showitbig
    authConfig: {
      clientID: 'dgq2dHqb3SI7opU4eLDORKlmucGDmEvb',
      domain: 'admin-vendor.auth0.com',
      responseType: 'token id_token',
      audience: 'sib-admin-api',
      redirectUri: 'http://vendor-admin.showitbig.com:4300/',
      scope: 'openid'
    }
  };

  private devEnvironment = {
    authApiUrl: 'https://dev-admin-showitbig.auth0.com/api/v2/', // auth0 management api identifier
    apiAccessTokenUrl: 'https://dev-admin-showitbig.auth0.com/oauth/token', // auth0 authz generic application token url in endpoints
    authConfig: { // application admin-showitbig
      clientID: 'KwvYXqyMvGGbk3vuBxQof7MkbCkRPBt4',
      domain: 'dev-admin-showitbig.auth0.com',
      responseType: 'token id_token',
      audience: 'sib-admin-api', // custom api created for application
      redirectUri: 'https://dev-admin.showitbig.com', // allowed web origin of audience
      scope: 'openid'
    }
  };

  private qaEnvironment = {
    authApiUrl: 'https://qa-admin-showitbig.auth0.com/api/v2/', // auth0 management api identifier
    apiAccessTokenUrl: 'https://qa-admin-showitbig.auth0.com/oauth/token', // auth0 authz generic application token url in endpoints
    authConfig: { // application admin-showitbig
      clientID: 'B2vnxPI8Fexp2lv4XiVP3myjpBjuhzZm',
      domain: 'qa-admin-showitbig.auth0.com',
      responseType: 'token id_token',
      audience: 'sib-qa-admin-api', // custom api created for application
      redirectUri: 'https://qa-admin.showitbig.com', // allowed web origin of audience
      scope: 'openid'
    }
  };

  private prodEnvironment = {
    authApiUrl: 'https://admin-showitbig.auth0.com/api/v2/', // auth0 management api identifier
    apiAccessTokenUrl: 'https://admin-showitbig.auth0.com/oauth/token', // auth0 authz generic application token url in endpoints
    authConfig: { // application admin-showitbig
      clientID: '59yILeXxdC2O9s3qFIVguKKAt7Ninn3u',
      domain: 'admin-showitbig.auth0.com',
      responseType: 'token id_token',
      audience: 'sib-admin-api', // custom api created for application
      redirectUri: 'https://admin.showitbig.com', // allowed web origin of audience
      scope: 'openid'
    }
  };

  constructor(
    // private sibMasterService: SibMasterService
  ) {
    if (document.location.href.indexOf('admin-vendor') > -1 || document.location.href.indexOf('vendor-admin') > -1) {
      this.config = this.admin_vendor;
    } else if (document.location.href.indexOf('dev-admin.showitbig.com') > -1) {
      this.config = this.devEnvironment;
    } else if (document.location.href.indexOf('qa-admin.showitbig.com') > -1) {
      this.config = this.qaEnvironment;
    } else {
      this.config = this.prodEnvironment;
    }
  }

  getConfig() {
    if (document.location.href.indexOf('admin-vendor') > -1 || document.location.href.indexOf('vendor-admin') > -1) {
      this.config = this.admin_vendor;
    } else if (document.location.href.indexOf('dev-admin.showitbig.com') > -1) {
      this.config = this.devEnvironment;
    } else if (document.location.href.indexOf('qa-admin.showitbig.com') > -1) {
      this.config = this.qaEnvironment;
    } else {
      this.config = this.prodEnvironment;
    }
    return this.config;
  }
}
