<!--The content below is only a placeholder and can be replaced.-->
<div style="text-align:center">
  <h1>
    Welcome to {{ title }}!
  </h1>
</div>
<div>
  <a (click)="logout()">Sign out</a>
</div>

<router-outlet></router-outlet>
